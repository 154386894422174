import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Clean&Co Services
			</title>
			<meta name={"description"} content={"Ваш дім, наша місія"} />
			<meta property={"og:title"} content={"Головна | Clean&Co Services"} />
			<meta property={"og:description"} content={"Ваш дім, наша місія"} />
			<meta property={"og:image"} content={"https://quantumixfix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quantumixfix.com/img/5526086.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quantumixfix.com/img/5526086.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quantumixfix.com/img/5526086.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quantumixfix.com/img/5526086.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quantumixfix.com/img/5526086.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quantumixfix.com/img/5526086.png"} />
			<meta name={"msapplication-TileColor"} content={"https://quantumixfix.com/img/5526086.png"} />
		</Helmet>
		<Components.TukazaeHeader />
		<Section padding="112px 0 112px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0.7) 100%),rgba(0, 0, 0, 0) url(https://quantumixfix.com/img/1.jpg) 50% 50% /cover no-repeat scroll padding-box" md-padding="96px 0 96px 0" sm-padding="72px 0 72px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Clean&Co Services
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					У Clean&Co Services ми віримо, що чистий дім - це щасливий дім. Наша місія - надавати виняткові клінінгові послуги, які залишають ваш простір бездоганним, організованим і привабливим. Незалежно від того, чи потрібне вам одноразове глибоке прибирання, чи регулярне обслуговування, наша професійна команда завжди готова забезпечити чистоту та затишок у вашій оселі.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Зв'яжіться з нами
					</Link>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Про нас
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
				Заснована на принципах надійності, якості та довіри, компанія Clean&Co Services стала провідним постачальником послуг з прибирання житла. Наші досвідчені прибиральники навчені справлятися з кожним завданням з точністю та дбайливістю. Ми використовуємо екологічно чисті засоби, безпечні для вашої родини та домашніх улюбленців, забезпечуючи не лише чистоту, але й здоров'я вашої оселі.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://quantumixfix.com/img/2.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
					/>
					<Image
						src="https://quantumixfix.com/img/3.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="2 / span 1"
						grid-row="2 / span 2"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" align-content="flex-start">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline1" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Чому варто обирати послуги Clean&Co?
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="start"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Команда експертів
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Наші прибиральники - висококваліфіковані фахівці, які пишаються своєю роботою.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Екологічно чисті продукти
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Ми використовуємо екологічно чисті засоби для прибирання, безпечні для вашої оселі та довкілля.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Індивідуальні плани
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Індивідуальні плани прибирання відповідно до вашого графіку та вподобань.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" align-content="flex-start" id="menu">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="start"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://quantumixfix.com/img/4.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Генеральне прибирання
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Регулярне прибирання: Витирання пилу, прибирання пилососом і миття підлоги, щоб підтримувати чистоту і порядок в оселі.
						<br/>
						Прибирання поверхонь: Ретельне прибирання стільниць, столів та інших поверхонь.
						<br/>
						Вивіз сміття: Ефективне вивезення та утилізація побутового сміття.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://quantumixfix.com/img/5.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Глибоке прибирання
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Детальна увага: Прибирання за і під меблями, технікою та у важкодоступних місцях.
						<br/>
						Очищення затірки та плитки: Інтенсивне чищення для видалення бруду та жиру з плитки та затірки.
						<br/>
						Миття вікон: Бездоганне миття вікон та підвіконь для гарного огляду.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="0px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://quantumixfix.com/img/6.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						height="278px"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Прибирання перед заселенням/виселенням
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Прибирання порожньої нерухомості: Комплексне прибирання порожніх будинків для підготовки до заселення нових мешканців.
						<br/>
						Прибирання після здачі в оренду: Забезпечення бездоганної чистоти об'єктів оренди між орендарями.
						<br/>
						Останні штрихи: Полірування та деталізація для бездоганного вигляду.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="112px 0 0px 0" background="--color-darkL2" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 40px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Відчуйте різницю Clean&Co
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					width="80%"
				>
					Обираючи послуги Clean&Co, ви обираєте досконалість. Доручіть нам прибирання, щоб ви могли насолоджуватися свіжим і блискучим будинком щодня. Зв'яжіться з нами сьогодні, щоб дізнатися, як наші фахівці з прибирання можуть перетворити ваш житловий простір. Пам'ятайте, що в Clean&Co Services ваша оселя - це наша місія.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s linear 0s"
					hover-background="--color-orange"
					transition="background-color 0.2s linear 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.TukazaeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});